.page-erro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.page-erro h1{
    font-size: 6rem;
    color: #292929;
}
.page-erro h2{
    font-size: 0.9rem;
    margin-top: 15px;
    color: #686868;
}