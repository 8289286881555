*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
body{
  background-color: #fff;
}
.container{
  max-width: 80%;
}
.error{
  background-color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 5px;
  border-radius: 5px;
}

/*formatando a barra de rolagem*/
::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-thumb{
  margin: 1px;
  background-color: #7B0AFF;
  border-radius: 5px;
}