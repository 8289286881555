.title-area{
    margin: 50px 0px;
}
.title-text{
  color: #292929;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.125rem;
  margin-bottom: 6px;
}
.title-bar{
  width: auto;
  height: 2px;
  background-color: #7B0AFF;
}

@media (max-width: 895px){
  .title-text{
    font-size: 1rem;
  }
}
@media (max-width: 745px){
  .title-area{
    margin: 25px 0px;
  }
}