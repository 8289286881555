header{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px;
}
.logo{
    height: 35px;
}
.logo img{
    height: 100%;
}

.menu-area{
    display: flex;
}

.menu ul{
    display: flex;
}
.menu ul, 
.menu li{
    list-style: none; 
    margin: 0px;
    padding: 0px;
    font-size: 0.8125rem;
    font-weight: 600;
}
.menu a{
    height: 80px;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #141414;
}
.menu a:hover{
    border-bottom: 3px solid #7B0AFF;
    color: #7B0AFF;
}
.hamburger{
    display: none;
    position: relative;
    width: 35px;
    height: 35px;
    margin: 27px;
    transition: .5s ease-in-out;
    cursor: pointer;
}
.hamburger span{
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #7B0AFF;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
.hamburger span:nth-child(1){
    top: 0px;
}
.hamburger span:nth-child(2), .hamburger span:nth-child(3){
    top: 12px;
} 
.hamburger span:nth-child(4){
    top: 23px;
}
.hamburger.open span:nth-child(1){
    top: 18px;
    width: 0%;
    left: 50%;
}

.hamburger.open span:nth-child(2){
    transform: rotate(45deg);
}
.hamburger.open span:nth-child(3){
    transform: rotate(-45deg);
}
.hamburger.open span:nth-child(4){
    top: 18px;
    width: 0%;
    left: 50%;
}

/*menu dropdown*/
.dropdown{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dropdown:hover .sub-menu{
    display: initial;
}
.menu-dropdown{
    cursor: pointer;
    padding: 0 10px;
    height: 80px;
    font-size: 0.8125rem;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}
.perfil-dropdown{
    height: 100%;
    display: flex;
    align-items: center;
}
.perfil-dropdown img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.menu-dropdown:hover{
    border-bottom: 3px solid #7B0AFF;
    color: #7B0AFF;
}
.acount-open{
    display: initial !important;
}
.sub-menu{
    display: none;
    position: absolute;
    top: 100%;
    width: 250px;
    margin-top: 2px;
}
.acount{
    padding: 15px;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #7B0AFF;
    background-color: #141414a6;
}
.acount .login{
    max-width: 90%;
}
.acount .btn{
    font-weight: bold;
    font-size: 0.75rem;
    box-shadow: 2px 2px 4px #141414c7;
    margin: 0;
    width: 6em;
    height: 2.2em;
    cursor: pointer;
}
.acount .contact{
    display: flex;
    margin: 0;
}
.acount h1{
    text-transform: uppercase;
    text-align: center;
    font-size: 0.75rem;
    color: #fff;
    margin: 10px 0;
}
.acount span{
    color: #fff;
}
.acount input::placeholder{
    color: #BFC0C0;
}
.contact .icon{
    height: auto;
    color: #fff;
}
.contact .icon:hover{
    border: none;
}
.contact .icon:nth-child(1):hover{
    color: #000;
}
.contact .icon:nth-child(2):hover{
    color: #008000 ; 
}
.contact .icon:nth-child(3):hover{
    color: #0e76a8 ; 
}
.acount .linha {
    width: 90%;
    height: 1px;
    background-color: #CCC;
}


@media (max-width: 745px){
    .menu-area{
        align-items: center;
        flex-direction: column;
    }
    .menu-area .hamburger{
        display: flex;
        margin-left: 70vw;
        cursor: pointer;
    }
    .menu ul{
        display: none;
        flex-direction: column;
        width: 50vw;
        position: absolute;
        z-index: 99;
        right: 0px;
        background-color: rgba(0, 0, 0, 0.87);
        height: calc(100vh - 80px);
    }
    .menu ul:first-child{
        margin-top: -3px;
        padding-top: 10px;
    }
    .menu a{
        border-bottom: none;
        height: auto;
        font-weight: bold;
        font-size: 1rem;
        margin: 4.8px 0px 4.8px 40px;
        color: #fff;
    }
    .menu a:hover{
        border-bottom: none;
        color: #7B0AFF;
    }
    .close ul {
        display: flex;
    }
    /*menu drop-down*/
    .dropdown{
        align-items: flex-start;
    }
    .menu-dropdown{
        height: 60px;
        margin-left: 40px;
        font-size: 0.938rem;
        color: #fff;
    }
    .menu-dropdown:hover{
        color: #7B0AFF;
    }
    .sub-menu{
        top: 40%;
        margin-left: 50px;
    }
    .contact .icon{
        margin: 10px;
    }
    .menu-dropdown:hover{
        border-bottom: none;
    }
}

@media (max-width: 642px){
    .menu-area .hamburger{
        margin-left: 50vw;
    }
    .menu ul{
        width: 70vw;
    }
}

@media (max-width: 428px){
    .menu ul{
        width: 100vw;
    }
    .menu-area .hamburger{
        margin-left: 25vw;
    }
}
