@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;600&display=swap');

.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about .title-area{
    margin: 150px 0px 40px 0px;
}
.about-area{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 47px 0px 100px 0px;
}
.perfil-img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.perfil-img .area-img{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 250px;
    width: 250px;
    border-radius: 50%;
}
.area-img img{
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 50%;
    border: 3.2px solid #292929;
}
.area-img .circle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 3.2px solid #fff;
    border-bottom: 3.2px solid #fff;
    border-left: 3.2px solid #6509ce;
    border-right: 3.2px solid #6509ce;
    animation: aboutSpiner 8s linear infinite;
}
.perfil-img, .about-desc{
    width: 40vw;
}
.about-desc{
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    padding: 20px;
    border-radius: 15px;
    background-color: #f7f7f7;
    box-shadow: 0 5px 12px -8px #292929;
    color: #A1A1A1;
    font-family: 'Mulish', sans-serif;
}
.about-desc abbr{
    text-decoration: none;
}
.about-desc strong{
    font-weight: 600;
    color: #292929;
}

@media (max-width: 895px){
    .about-desc{
        font-size: 0.8rem;
    }
}

@media (max-width: 745px){
    .about-area{
        flex-direction: column;
        padding: 25px 0px;
    }
    .perfil-img, .about-desc{
        width: 80vw;
    }
    .perfil-img{
        margin-bottom: 30px;
    }
    .perfil-img .area-img{
        height: 200px;
        width: 200px;
    }
    .about-desc{
        width: 90%;
        text-align: justify;
    }

}

@keyframes aboutSpiner {
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
    }
}