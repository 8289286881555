.add-project{
    text-align: center;
    margin-top: 130px;
    padding-bottom: 38px;
}
.add-project h1{
    color: #292929;
}
.add-project p{
    color: #A1A1A1;
    margin-bottom: 30px;
}
input::placeholder{
  font-family: 'Montserrat', sans-serif;
  color: #A1A1A1;
}
input:focus{
  outline: none;
}
form{
    max-width: 40%;
    margin: 0 auto;
}
label{
    display: flex;
    flex-direction: column;
    margin-bottom: 1px;
}
label span{
    margin-bottom: .3em;
    font-weight: 600;
    text-align: left;
    color: #292929;
}
input{
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #CCC;
    padding: .8em 0;
    background-color: transparent;
    margin-bottom: 10px;
    color: #A1A1A1;
}

@media (max-width: 745px){
    form{
        max-width: 60%;
    }
}
@media (max-width: 475px){
    form{
        max-width: 80%;
    }
    .add-project h1{
        font-size: 1.75rem;
    }
}