.skills{
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skills-area ul, .projects-area ul{
    list-style: none;
    display: flex;
}
.skills-area ul:hover > :not(:hover){
    opacity: .4;
    transform: scale(0.9);
}
.skill{
    margin: 35px 0;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-left: 1px solid #4A0AFF;
    transition: .35s;
}
.skill:last-child{
    border-right: 1px solid #4A0AFF;
}
.skill .title{
    margin: 10px 0;
    font-weight: 600;
    color: #292929;
}

.skill .desc, .skill .exp{
    font-size: 0.75rem;
    text-align: justify;
    max-width: 182px;
}
.skill .desc{
    color: #8b8b8b;
    flex: 1;
}
.skill .exp{
    margin-top: 10px;
    color: #646464;
    font-weight: 600;
}

@media (max-width: 895px){
    .skill .logo{
        height: 60px;
    }
    .skill .title{
        font-size: 0.875rem;
    }
    .skill .desc, .skill .exp{
        font-size: 0.6875rem;
    }
}
@media (max-width: 800px){
    .skill{
        margin: 15px 0;
        border: none;
    }
    .skill:last-child{
        border-right: none;
    }
    .skills-area ul{
        flex-direction: column;
    }
}