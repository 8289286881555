@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@700&display=swap');

.banner{
    height: 100vh;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
}
.banner-info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #141414;
}
.first-line, .second-line{
    letter-spacing: 0.1em;
    text-align: center;
}
.first-line{
    font-size: 3.5rem;
}
.second-line{
    margin: 10px 0px 30px 0px;
    font-size: 2.8rem;
}
.title-item{
    display: inline-block;
    margin: 0 0.75rem;
    transition: all .6s ease;
    opacity: 0;
    color: #1E293B;
    font-weight: 700;
    font-family: 'Outfit', sans-serif;
    animation: title 1s ease forwards;
}
.sub-title{
    font-size: 1.5rem;
    color: #aaaaaabb;
}
.first-line .title-item:nth-child(2) {
    animation-delay: .2s;
}
.first-line .title-item:nth-child(3) {
    animation-delay: .3s;
}  
.first-line .title-item:nth-child(4) {
    animation-delay: .4s;
}
.first-line .title-item:nth-child(5) {
    animation-delay: .5s;
}

.second-line .title-item:nth-child(1) {
    animation-delay: .6s;
}
.second-line .title-item:nth-child(2) {
    background: repeating-linear-gradient(90deg,#2C0AFF 0,#7B0AFF 50%,#9F08FF);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation-delay: .7s;
} 
.banner-contact{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 50px;
}
.banner-contact a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #4A0AFF;
    padding: 0.7em 1.4em;
    font-size: 0.875rem;
    position: relative;
    background: transparent;
    user-select: none;
    overflow: hidden;
    z-index: 1;
    font-weight: 700;
}
.banner-contact a:nth-child(1){
    margin-right: 20px;
}
.banner-contact span{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: -1;
    border: 4px solid #4A0AFF;
}
.banner-contact span::before {
    content: "";
    display: block;
    position: absolute;
    width: 8%;
    height: 500%;
    background: #f7f7f7;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-60deg);
    transition: all 0.3s;
}
.banner-contact a:hover span::before {
    transform: translate(-50%, -50%) rotate(-90deg);
    width: 100%;
    background: #4A0AFF;
}
.banner-contact a:hover{
    color: #f7f7f7;
}

/*projects*/
.projects{
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projects-area ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.project, .project-right{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 15px;
}
.project-right{
    flex-direction: row-reverse;
}
.proj-logo, .proj-desc{
    width: 35vw;
}
.proj-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.proj-logo img{
    height: 300px;
    width: 350px;
    object-fit: cover;
    border-radius: 25px;
    box-shadow: 0 5px 12px -8px #292929;
}
.proj-links{
    display: flex;
    justify-content: center; 
    margin-top: 30px;
}
.project a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: #000000;
    font-weight: 600;
}
.proj-desc{
    display: flex;
    justify-content:center;
    align-items: center;
}
.proj-desc .desc-area{
    max-width: 350px;
}
.desc-area p{
    line-height: 21px;
    font-size: 0.8rem;
    text-align: justify;
    color: #A1A1A1;
    font-weight: 600;
}
.proj-skills{
    margin-top: 15px;
    font-weight: 600;
    color: #292929 !important;
}
.proj-title{
    margin-bottom: 25px;
    text-align: center;
    color: #292929;
}

/*botão projetos*/
.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin: 0px 10px 20px 10px;
    width: 7em;
    height: 2.5em;
    border-radius: 30em;
    font-size: 14px;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 3px 3px 10px #c5c5c5, -3px -3px 10px #ffffff;
}
.btn::before {
    content: '';
    width: 0;
    height: 2.5em;
    border-radius: 30em;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #2C0AFF 0%, #7B0AFF 100%);
    transition: .5s ease;
    display: block;
    z-index: -1;
}

.btn:hover::before {
    width: 9em;
}

/*animação*/
@keyframes title {
    0%{
        opacity: 0;
        transform: translate(10px,10px);
    }
    50% {
        opacity: .8;
        transform: translate(-11px,-11px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

/*responsividade*/
@media (max-width: 1165px){
    .first-line{
        font-size: 3rem;
    }
    .second-line{
        font-size: 2.3rem;
    }
    .sub-title{
        font-size: 1.3rem;
    }
}

@media (max-width: 1005px){
    .proj-logo, .proj-desc{
        width: 40vw;
    }
    .first-line{
        font-size: 2.5rem;
    }
    .second-line{
        font-size: 1.8rem;
    }
    .sub-title{
        font-size: 1rem;
    }

}
@media (max-width: 895px){
    .banner-contact a{
        font-size: 0.75rem;
    }

    /*projetos*/
    .project, .project-right{
        flex-direction: column;
        margin: 30px 0px;
    }
    .proj-logo{
        margin-bottom: 30px;
    }
    .proj-logo, .proj-desc{
        width: 60vw;
    }
    .btn{
        font-size: 0.75rem;
    }
    
}

@media (max-width: 768px){
    .first-line{
        font-size: 3rem;
    }
    .second-line{
        font-size: 2.3rem;
    }
    .sub-title{
        font-size: 1.3rem;
    }
    .proj-logo, .proj-desc{
        width: 80vw;
    }
}
@media (max-width: 590px){
    .sub-title{
        font-size: 1rem ;
    }
}
@media (max-width: 430px){
    .proj-logo img{
        height: 200px;
        width: 300px;
    }
    .sub-title{
        font-size: 0.85rem;
        text-align: center;
    }
}
